import LivoIcon from "assets/LivoLogo";
import { CategoryTag } from "components/common/CategoryTag";
import { TagLabel } from "components/common/TagLabel";
import { ModalityTag } from "components/internal/ModalityTag";
import { ShiftModalityEnum } from "types";
import { Category } from "types/shifts";

interface ShiftCardHeaderProps {
    title: string;
    recurrent: boolean;
    style?: any
    skills: {
        displayText: string;
        value: string;
    }[]
    category?: Category;
    internalVisible: boolean;
    externalVisible: boolean;

}

export const ShiftCardHeader: React.FC<ShiftCardHeaderProps> = ({
    title,
    recurrent,
    skills,
    style,
    category,
    internalVisible,
    externalVisible
}) => {
    return (
        <div
            className="flex flex-col w-full"
        >
            {category || internalVisible || externalVisible ? <div
                className="flex flex-row items-center space-x-tiny mb-[4px]"
            >
                {category ? <CategoryTag
                    text={category.acronym} /> : null}
                {internalVisible ?
                    <ModalityTag
                        modality={ShiftModalityEnum.INTERNAL}
                        shortTag={true}
                    /> : null}
                {
                    externalVisible ?
                        <ModalityTag
                            modality={ShiftModalityEnum.EXTERNAL}
                            shortTag={true}
                        />
                        : null
                }
            </div> : null}
            <div
                className="flex items-center flex-row justify-start space-x-tiny mb-tiny"
                style={style}
            >
                {recurrent ? <LivoIcon name='repeat' size={16} color='#ACBBCB' /> : null}
                <h1 className="subtitle-regular flex flex-1 truncate">
                    {title}
                </h1>
            </div>
            <div
                className="flex-row space-x-small items-center justify-start gap-tiny flex-wrap inline-flex"
            >
                {skills.map(skill =>
                    <TagLabel
                        text={skill.displayText}
                    />)
                }
            </div>
        </div>

    )
}