import { Category } from "../../types/shifts";
import LivoIcon from "../../assets/LivoLogo";
import { CategoryTag } from "../../components/common/CategoryTag";

interface LargeCardHeaderProps {
    title: string;
    recurrent: boolean;
    style?: any
    category?: Category;
    internalVisible: boolean;
    externalVisible: boolean;
}

export const LargeCardHeader: React.FC<LargeCardHeaderProps> = ({
    title,
    recurrent,
    style,
    category,
}) => {
    return (
        <div
            className="flex flex-row w-full space-x-small"
        >
            <div
                className="flex items-center flex-row justify-start space-x-tiny"
                style={style}
            >
                {category ? <CategoryTag
                    text={category.acronym} /> : null}
                {recurrent ? <LivoIcon name='repeat' size={16} color='#ACBBCB' /> : null}
                <h1 className="subtitle-regular flex flex-1 truncate">
                    {title}
                </h1>
            </div>
        </div>

    )
}