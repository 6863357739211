import { ShiftModalityEnum } from "types";
import { InternalProfessional } from "types/internal";
import { EditCategoryComponent } from "./EditCategoryComponent";
import { Category } from "types/shifts";
import { ProfilePicture } from "components/shiftDetails/ProfilePicture";
import { RootState } from "store/types";
import { useSelector } from "react-redux";

interface EditProfessionalCardHeaderProps {
    professionalProfile: InternalProfessional
    modality: ShiftModalityEnum | null;
    selectedCategory: Category,
    availableCategories: Category[],
    setSelectedCategory: (category: Category) => void
}
export const EditProfessionalCardHeader: React.FC<EditProfessionalCardHeaderProps> = ({
    professionalProfile,
    modality,
    selectedCategory,
    availableCategories,
    setSelectedCategory
}) => {
    const { accountInfo } = useSelector((state: RootState) => state.account)
    return (

        <div
            className="flex flex-col justify-center items-center w-full space-y-small pb-large"
        >
            <ProfilePicture profilePictureUrl={professionalProfile?.profilePictureUrl} modality={modality} size={64} />
            <div
                className="flex flex-col items-center w-full px-small"
            >
                <p className="heading-md text-Text-Default">
                    {professionalProfile?.firstName} {professionalProfile.lastName
                    }</p>
                {
                    accountInfo?.facility.categories && accountInfo.facility.categories.length > 0 ?
                        <EditCategoryComponent
                            hasChanged={selectedCategory.code !== professionalProfile.category.code}
                            selectedCategory={selectedCategory}
                            availableCategories={availableCategories}
                            setSelectedCategory={setSelectedCategory}
                        /> : null}
            </div>
        </div>
    )
}