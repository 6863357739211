import { NotificationsBadge } from "components/common/NotificationsBadge";
import { TagComponent } from "../../components/common/TagComponent";
import { FilterConfiguration } from "../../types/shifts";


interface ProfessionalFilterProps {
    setFilter: (filters: string) => void;
    appliedFilter: string;
    nPendingClaims: number

}
export const FILTER_CONFIGURATIONS: {
    id: string,
    label: string,
    configuration: FilterConfiguration,
}[] = [
        {
            id: 'all',
            label: "Todos",
            configuration: {
            }
        },
        {
            id: 'pending',
            label: "Solicitudes",
            configuration: {
                withPendingClaims: true
            },
        }
    ]
export const ProfessionalFilter: React.FC<ProfessionalFilterProps> = ({
    setFilter,
    appliedFilter,
    nPendingClaims
}) => {
    return (
        <div
            className="flex space-x-tiny w-full items-center pb-large flex-wrap"
        >
            {
                FILTER_CONFIGURATIONS.map((filter, index) => (
                    <TagComponent
                        key={index}
                        label={filter.label}
                        onClick={() => {
                            setFilter(filter.id)
                        }}
                        isSelected={appliedFilter.includes(filter.id)}
                        style={filter.id === 'pending' && nPendingClaims ? {
                            padding: '6px 8px',
                        }: undefined}
                    >
                        {
                            filter.id === 'pending' && nPendingClaims ?
                           <NotificationsBadge 
                                notifications={nPendingClaims} 
                                style={{
                                    marginLeft: '4px'
                                }}
                                />
                           
                           : null
                        }
                    </TagComponent>
                ))
            }
        </div>
    )
}