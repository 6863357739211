import { ShiftSummary, fetchShiftsSummary } from "services/shifts-calendar"
import { CalendarMonth } from "./CalendarMonth";
import { useEffect, useState } from "react";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store/types";

interface CalendarSummaryProps {
    onDayPress: (day: string) => void;
    shiftSummary: ShiftSummary[];
    loading: boolean;
}

export const CalendarSummary: React.FC<CalendarSummaryProps> = ({
    onDayPress,
    shiftSummary,
    loading
}) => {
    const { selectedDate } = useSelector((state: RootState) => state.calendarSummary)
    const [currentMonth, setCurrentMonth] = useState(selectedDate.toString())

    return (
        <div
            className="flex flex-col bg-white h-full justify-start pt-[20px] px-medium self-start border-r-[1px] border-solid border-Divider-Subtle w-[256px]"
        >
            {loading ?

                <div
                    className="flex flex-col items-center justify-center h-full"
                >
                    <CircularProgress />
                </div> :
                <CalendarMonth
                    onDayPress={(day: string) => {
                        onDayPress(day)
                        if (moment(day).month() !== moment(currentMonth).month()) {
                            setCurrentMonth(moment(day).startOf('month').format('YYYY-MM-DD'))
                        }
                    }}
                    calendarSummary={shiftSummary}
                    monthToRender={currentMonth}
                    daysSelected={[selectedDate.toString()]}
                    onMonthChange={(month: string) => {
                        setCurrentMonth(month)
                    }}
                />}
        </div>
    )
}