export const VIEW_MORE = 'Ver mas';
export const VIEW_LESS = 'Ver menos';
export const VIEW_CV_LABEL = 'Curriculum';
export const LICECNSE_NUMBER_LABEL = 'Número de licencia';
export const PROFESSIONAL_DATA_TITLE = 'Datos del profesional';
export const EXPERIENCE_LABEL = 'Experiencia';
export const FACILITY_EXPERIENCE = 'Experiencia en el centro';
export const COMPLETED_SHIFTS = 'Turnos completados';
export const IN = 'en';
export const LIVO_EXPERIENCE = 'Experiencia en Livo';
export const SINGLE_REVIEW_LABEL = 'valoración';
export const REVIEWS_LABEL = 'valoraciones';
export const INTERNAL_ASSIGNED_UNIT = 'Unidad asignada';
export const INTERNAL_IDENTIFICATION_NUMBER = 'Número de identificación';
export const INTERNAL_CONTRACT_TYPE = 'Tipo de contrato';
export const FIRST_SHIFTER_LABEL = 'Primera vez';
export const TOTAL_REVIEWS_TITLE = 'Total';