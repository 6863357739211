import { ClaimRequest } from "types/claims";
import { ProfilePicture } from "./ProfilePicture";
import LivoIcon from "assets/LivoLogo";
import { ShiftModalityEnum } from "types";
import { PENDING_TO_FILL_CAPACITY_ITEM } from "locales/shiftClaimDetails/es";

interface EmptyCapacityClaimProps {
    onClick: () => void;
    editable: boolean;
}

export const EmptyCapacityClaim: React.FC<EmptyCapacityClaimProps> = ({ onClick, editable }) => {
    return (
        <div
            className="flex flex-row items-center space-x-small h-full w-full py-small"
        >
            <div className={`flex items-center justify-center rounded-[8px] bg-Neutral-100 p-medium`}>
                <LivoIcon name='user-search' size={24} color='#707A91' />
            </div>
            <div
                className="flex flex-col justify-center w-full"
            >
                <p className="body-regular text-Text-Default">{PENDING_TO_FILL_CAPACITY_ITEM}</p>
            </div>
            {editable ? <button
                className="flex items-center justify-center"
                onClick={onClick}
            >
                <LivoIcon name='trash' size={24} color='#ACBBCB' />
            </button> : null}
        </div>
    )
}