import { ProfessionalDataField } from "./claims"
import { Category } from "./shifts"

export enum InvitationStatus  {
    ACCEPTED = 'ACCEPTED',
    PENDING = 'PENDING'
}

export type InternalProfessional = {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    enabled: boolean,
    profilePictureUrl: string,
    invitationStatus: InvitationStatus,
    dataFields: ProfessionalDataField[]
    category: Category;
}

export type DataFieldSubmission = {
    key: string,
    selectedValues: string[] // string is the value of the selected option, or the text for freeText
}

export type DataFieldOption = {
    value: string,
    displayText: string,
}

export enum DataFieldType {
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    FREE_TEXT = 'FREE_TEXT',
}

export type FacilityDataFieldDefinition = {
    key: string,
    label: string,
    type: DataFieldType,
    options: DataFieldOption[]
}
