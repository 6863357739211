
import LivoIcon from "assets/LivoLogo";
import { ActionButton } from "components/common/ActionButton";
import { ModalContainer } from "components/common/ModalContainer";
import { SingleSelectItem } from "components/common/SingleSelectItem"
import { CANCEL_SHIFT_MODAL_GO_BACK, CANCEL_SHIFT_RECURRENT_BUTTON, CANCEL_SHIFT_RECURRENT_TITLE } from "locales/shiftClaimDetails/es";
import { useEffect, useState } from "react";
import { RecurrencyOptionsEnum, recurrencyOptions } from "types/publish-shift";
import { Shift } from "types/shifts";
import { formatDate } from "utils";


interface CancelRecurrentShiftModalProps {
    goBack: () => void,
    cancelShift: (option: string) => void,
    isOpen: boolean,
    selectedOption: RecurrencyOptionsEnum
    setSelectedOption: (option: RecurrencyOptionsEnum) => void
    shiftDetails: Shift;
}

export const CancelRecurrentShiftModal: React.FC<CancelRecurrentShiftModalProps> = ({
    goBack,
    cancelShift,
    isOpen,
    selectedOption,
    setSelectedOption,
    shiftDetails
}) => {

    const [recurrentDateExpanded, setRecurrentDatesExpanded] = useState<boolean>(false)


    const handleGoBack = () => {
        goBack()
    }

    useEffect(() => {
        if(!isOpen) {
            setRecurrentDatesExpanded(false)
        }
    }, [isOpen])

    const recurrendDatesComponent =
        <div>
            <div
                onClick={() => setRecurrentDatesExpanded(!recurrentDateExpanded)}
                className="flex flex-row items-center space-x-small mb-small cursor-pointer"
            >
                <p className="action-regular text-Action-Primary">
                    {recurrentDateExpanded ? 'Ver menos' : 'Ver más'}
                </p>
                <LivoIcon name={recurrentDateExpanded ? 'chevron-up' : 'chevron-down'} size={24} color='#149EF2' />
            </div>
            {recurrentDateExpanded ? <div
                className="flex flex-col w-full space-y-tiny py-small px-medium ring-[1px] ring-Divider-Default rounded-[8px] overflow-y-auto max-h-[400px]"
            >
                <p
                    className="info-caption"
                >
                    {`Se eliminarán ${shiftDetails.recurrentDates.length} turnos de ${shiftDetails.specialization.translations.es}:`}
                </p>
                <div>
                    {shiftDetails.cancellableShiftDates.map((date, index) => (
                        <p
                            className="body-regular text-Text-Subtle"
                        >
                            - {formatDate(date)}
                        </p>
                    ))
                    }
                </div>
            </div> : null}
        </div>


    return (
        <ModalContainer
            isOpen={isOpen}
        >

            <div
                className="rounded-[16px] shadow-custom w-[410px] bg-white"
            >
                <div
                    className="py-medium px-[24px]"
                >

                    <p
                        className="heading-md mb-large"
                    >
                        {CANCEL_SHIFT_RECURRENT_TITLE}
                    </p>
                    <div
                        className="flex flex-col space-y-large"
                    >
                        {
                            recurrencyOptions.map((option, index) => (
                                <div
                                    key={index}
                                    className="flex flex-col w-full"
                                >
                                    <SingleSelectItem
                                        option={
                                            option.label
                                        }
                                        onClick={() => setSelectedOption(option.name)}
                                        checked={selectedOption === option.name}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    {recurrendDatesComponent}
                    <div
                        className="flex flex-row items-center mt-large"
                    >
                        <button
                            onClick={() => handleGoBack()}
                            className="flex flex-1 text-center text-Action-Primary items-center justify-center  py-large px-small"
                        >
                            <p
                                className="action-regular w-full"
                            >
                                {CANCEL_SHIFT_MODAL_GO_BACK}
                            </p>

                        </button>
                        <ActionButton
                            onClick={() => cancelShift(selectedOption)}
                            isDisabled={false}
                            isLoading={false}
                            style={{
                                flex: 1,
                            }}
                            color={'#FF5A59'}
                        >
                            <p
                                className="w-full action-regular py-small"
                            >
                                {CANCEL_SHIFT_RECURRENT_BUTTON}
                            </p>
                        </ActionButton>
                    </div>
                </div>
            </div>
        </ModalContainer>
    )
}