import React from 'react';
import { Typography, Box, Card, CardMedia, IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';

const FileThumbnail = ({ fileUrl, label }: { fileUrl: string, label: string }) => {
    const getFileExtension = (filename: string) => {
        return filename.split("?")[0].split('.').pop()?.toLowerCase() || 'unknown';
    };

    const fileExtension = getFileExtension(fileUrl);

    let icon = <Download />;

    return (
        <Card sx={{ width: 150, margin: 2 }}>
            <CardMedia
                component={fileExtension === 'pdf' ? "iframe" : 'img'}
                height="100"
                image={fileUrl}
            />
            <Box sx={{ maxHeight: 0.5, display: 'flex', alignItems: 'center' }}>
                <IconButton
                    aria-label="Download"
                    onClick={() => {
                        window.open(fileUrl, '_blank');
                    }}
                    sx={
                        {
                            width: '100%',
                            height: '100%',
                            borderRadius: 0,
                            justifyContent: 'left',
                            pr: 1
                        }
                    }
                >
                    {icon}
                    <Typography variant="body2" component="div" noWrap sx={{ marginLeft: 1 }}>
                        {label}
                    </Typography>
                </IconButton>
            </Box>
        </Card>
    );
};

export default FileThumbnail;
