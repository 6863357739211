import React, { useEffect, useRef, useState } from "react";
import LivoIcon from "assets/LivoLogo";

type Option = {
    id: string;
    name: string;
};

interface DropDownWithInputProps {
    setOptionId: (id: string) => void;
    selectedOptionId: string;
    placeHolder?: string;
    errorMessage?: string;
    options?: Option[];
    disabled?: boolean;
    hasChanged?: boolean;
    callToActionIcon?: string;
}

export const DropDownWithInput: React.FC<DropDownWithInputProps> = ({
    setOptionId,
    selectedOptionId,
    placeHolder,
    errorMessage,
    options = [],
    disabled,
    hasChanged,
    callToActionIcon,
}) => {
    const selectedOptionName = options.find(option => option.id === selectedOptionId)?.name || '';
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const dropdownRef = useRef<HTMLUListElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const matchingOptionIndex = options.findIndex(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));

    useEffect(() => {
        setInputValue(selectedOptionName);
    }, [selectedOptionName]);

    useEffect(() => {
        if (isFocused && inputValue && dropdownRef.current && matchingOptionIndex !== -1) {
            const matchingOptionElement = dropdownRef.current.children[matchingOptionIndex] as HTMLLIElement;
            matchingOptionElement.scrollIntoView({ block: 'nearest' });
        }
    }, [inputValue, isFocused, options, matchingOptionIndex]);

    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleOptionClick = (option: Option) => {
        setOptionId(option.id);
        setInputValue('');
        setIsFocused(false);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
            setInputValue('');
        }, 100);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && matchingOptionIndex !== -1) {
            e.preventDefault();
            const matchingOption = options[matchingOptionIndex];
            setOptionId(matchingOption.id);
            setInputValue('');
            setIsFocused(false);
            inputRef.current?.blur();
        }
    };

    return (
        <div className="flex flex-col w-full relative">
            <div
                className={`flex flex-row box-border ring-[1px] ring-solid rounded-[8px] px-small w-full space-x-small justify-start items-center py-medium ${!disabled ? 'bg-white' : 'bg-Background-Secondary'} flex-shrink-1 
                    ${errorMessage ? 'ring-[2px] ring-red-500' : 'ring-Divider-Subtle'} focus-within:ring-Action-Secondary focus-within:ring-[2px]`}
            >
                {hasChanged && !isFocused ? <div
                    className="w-small h-small bg-Action-Primary rounded-full"
                /> : null}
                <input
                    ref={inputRef}
                    disabled={disabled}
                    type="text"
                    value={isFocused ? inputValue : selectedOptionName}
                    onChange={handleInputValue}
                    onBlur={handleInputBlur}
                    className="body-regular w-full placeholder-Text-Subtle focus:outline-none placeholder:body-regular placeholder:text-Text-Subtle bg-transparent"
                    placeholder={placeHolder}
                    onFocus={handleInputFocus}
                    onKeyDown={handleKeyPress}
                />
                {
                    callToActionIcon ? (
                        <div className="items-center justify-center">
                            <LivoIcon name={callToActionIcon} size={24} color={'#149EF2'} />
                        </div>
                    ) : null
                }
            </div>
            {isFocused && options.length > 0 && (
                <ul ref={dropdownRef} className="absolute bg-white border-[1px] border-Divider-Default rounded-[8px] mt-tiny w-full max-h-60 overflow-auto z-10 top-full">
                    {options.map((option, index) => (
                        <li
                            key={option.id}
                            className={`body-regular py-medium px-large cursor-pointer ${inputValue && index === matchingOptionIndex ? 'bg-Background-Secondary' : ''} hover:bg-Background-Secondary`}
                            onMouseDown={() => handleOptionClick(option)}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
            {errorMessage && (
                <p className="info-caption mt-tiny text-Negative-500">
                    {errorMessage}
                </p>
            )}
        </div>
    );
};
