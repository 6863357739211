// src/Sidebar.js
import LivoIcon from 'assets/LivoLogo';
import { AccountModal } from 'components/account/AccountModal';
import { SideBarButton } from 'components/common/SideBarButton';
import { RouteProps } from 'pages/Router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, } from 'react-router-dom';
import { VisibleTabEnum } from 'services/account';
import { AppDispatch } from 'store';
import { fetchActivity } from 'store/actions/activityShiftListActions';
import { RootState } from 'store/types';

interface SidebarProps {
  logOut: () => void;
  routes: RouteProps[];
}
const Sidebar: React.FC<SidebarProps> = ({
  logOut,
  routes,
}) => {
  const location = useLocation();
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);
  const getSelectedRoute = (path: string) => {
    return location.pathname === path;
  };
  const navigate = useNavigate()
  const dispatch= useDispatch<AppDispatch>()
  const handleNavigation = (path: string) => {
    navigate(path);
  };
  const { activityShiftList } = useSelector((state: RootState) => state)


  useEffect(() => { //poll for activity notification badge, in case we need any other we might migrate this to a polling api
    if (routes.some((route) => route.id === VisibleTabEnum.ACTIONABLE_SHIFTS)) {
        const interval = setInterval(() => {
            dispatch(fetchActivity())
        }, 60000)


        return () => {
            clearInterval(interval)
        }
    }
}, [routes.length])

  return (
    <div className="content flex flex-col bg-white border-r border-Divder-Subtle justify-between  py-[20px] ">
      <div
        className='flex-1 flex flex-col'
      >
        <ul>
          {routes.map((route, index) => (
          
          <li 
          key={index}
          className="px-medium mb-[20px]">
            <SideBarButton
              onClick={() => {
                handleNavigation(route.path);
              }}
              name={route.iconName} isFocused={
                getSelectedRoute(route.path)
              }
              notifications={route.id === VisibleTabEnum.ACTIONABLE_SHIFTS ? activityShiftList.shifts.length : undefined}
            />
          </li>))
          }
        </ul>
      </div>
      <div
        className='flex flex-col items-center'
      >
        <SideBarButton
          onClick={() => {
            setAccountModalOpen(true);
          }}
          name={'settings'}
          isFocused={false} />
        <SideBarButton
          onClick={() => {
            logOut()
            navigate('/')
          }}
          name={'log-out'}
          isFocused={false} />
      </div>
      <AccountModal
        isOpen={accountModalOpen}
        onClose={() => {
          setAccountModalOpen(false);
        }}
      />
    </div>
  );
};

export default Sidebar;
