import React from 'react';

interface ModalContainerProps {
    children: any,
    isOpen: boolean,
    style?: any
}

export const ModalContainer: React.FC<ModalContainerProps> = ({
    children,
    isOpen,
    style
}) => {
    const modalVisibilityClass = isOpen ? 'opacity-100 visible' : 'opacity-0 invisible';
    const backdropVisibilityClass = isOpen ? 'opacity-50 visible' : 'opacity-0 invisible';

    return (
        <div className={`flex justify-center fixed z-50 inset-0 overflow-y-auto items-center transition-opacity duration-300 ${modalVisibilityClass}`}>
            <div className={`fixed inset-0 bg-black transition-opacity duration-300 ${backdropVisibilityClass}`}></div>
            <div className="relative z-10" style={style}>
                {children}
            </div>
        </div>
    )
}
