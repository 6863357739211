import { ClaimRequest, ClaimStatusEnunm } from "types/claims"
import { ProfessionalCardHeader } from "./ProfessionalCardHeader";
import { ProfessionalFacilityExperience } from "./ProfessionalFacilityExperience";
import { ProfessionalLivoReviews } from "./ProfessionalLivoReviews";
import LivoIcon from "assets/LivoLogo";
import { CANCELLATION_REQUEST_REASON, PENDING_CLAIMS_TITLE } from "locales/shiftClaimDetails/es";
import { ManageClaimContainer } from "./ManageClaimContainer";
import { CompensationOptionComponent } from "./CompensationOption";
import { InternalProfessionalAttributes } from "./InternalProfileAttributes";
import { INTERNAL_ASSIGNED_UNIT, INTERNAL_CONTRACT_TYPE, INTERNAL_IDENTIFICATION_NUMBER } from "locales/professionalClaim";
import { SlotReasonDetails } from "./SlotReasonDetails";

interface InternalProfessionalClaimDetailsProps {
    claim: ClaimRequest;
    goBack: () => void;
    onAccept: () => void;
    onReject: () => void;
    acceptCancellationRequest: (claimId: number) => void;
    rejectCancellationRequest: (claimID: number) => void;
    onUpdateSlotReason: () => void;
    shiftId: number;
}
export const InternalProfessionalClaimDetails: React.FC<InternalProfessionalClaimDetailsProps> = ({
    claim,
    goBack,
    onAccept,
    onReject,
    acceptCancellationRequest,
    rejectCancellationRequest,
    onUpdateSlotReason,
    shiftId
}) => {



    return (
        <div
            className="flex flex-col w-full"
        >
            <button
                onClick={goBack}
                className="flex w-full space-x-small items-center justify-start px-small mb-small"
            >
                <LivoIcon name='arrow-left' size={24} color='#ACBBCB' />
            </button>
            <div
                className="flex flex-col w-full h-full justify-between pb-large"
            >
                <div
                    className="flex flex-col w-full space-y-large divide-y-[1px] divide-Divider-Default"
                >
                    <div
                        className="flex flex-col w-full"
                    >

                        <ProfessionalCardHeader
                            professionalProfile={claim.professionalProfile}
                            modality={claim.modality}
                        />
                        {claim.professionalProfile.internal && <InternalProfessionalAttributes
                            attributes={
                                [
                                    ...claim.professionalProfile.internal.dataFields.map(datafield => ({
                                        label: datafield.label,
                                        value: datafield.displayText
                                    })
                                    )
                                ]

                            }
                        />}
                    </div>
                    {claim.compensationOption && <CompensationOptionComponent
                        compensationOption={claim.compensationOption}
                    />}
                    {
                        claim.status !== ClaimStatusEnunm.PENDING_APPROVAL && claim.slotReasonOptions && claim.slotReasonOptions.length > 0 ? <SlotReasonDetails
                            onUpdateSlotReason={onUpdateSlotReason}
                            slotReason={claim.slotReason}
                            slotReasonOptions={claim.slotReasonOptions}
                            claimId={claim.id}
                            shiftId={shiftId}
                        /> : null
                    }
                </div>
                {claim.status === ClaimStatusEnunm.PENDING_APPROVAL ?
                    <div
                        className="flex flex-col w-full px-medium"
                    >
                        <ManageClaimContainer
                            onAccept={onAccept}
                            onReject={onReject}
                        >
                        </ManageClaimContainer>
                    </div> :
                    claim.cancellationRequest ?
                        <div
                            className="flex flex-col w-full px-medium"
                        >
                            <ManageClaimContainer
                                onAccept={() => acceptCancellationRequest(claim.id)}
                                onReject={() => rejectCancellationRequest(claim.id)}
                            >
                                <div
                                    className="flex flex-col w-full space-y-tiny"
                                >
                                    <p
                                        className="body-regular text-Text-Subtle"
                                    >
                                        {CANCELLATION_REQUEST_REASON}
                                    </p>
                                    <p
                                        className="body-regular text-Text-Default"
                                    >
                                        {claim.cancellationRequest.reason}
                                    </p>
                                </div>
                            </ManageClaimContainer>
                        </div>
                        : null

                }

            </div>

        </div>
    )
}