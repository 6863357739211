import { CONFIRMED_TAG_LABEL, IN_REVIEW_TAG_LABEL, UNAVAILABLE_TAG_LABEL } from "locales/shiftClaimList/es";
import { ShiftModalityEnum } from "types";

export enum FacilityReviewStatusEnum {
    CONFIRMED = "CONFIRMED",
    UNAVAILABLE = "UNAVAILABLE",
    IN_REVIEW = "IN_REVIEW"
}

type ColorThemeEnum = "success" | "default" | "warning";

type ReviewStatusLabelProps = {
  [key in FacilityReviewStatusEnum]: {
    label: string;
    colorTheme: ColorThemeEnum;
  };
};


export const reviewStatusLabelProps: ReviewStatusLabelProps = {
    UNAVAILABLE: {
        label: UNAVAILABLE_TAG_LABEL,
        colorTheme: 'default'
    },
    IN_REVIEW: {
        label: IN_REVIEW_TAG_LABEL,
        colorTheme: 'warning'
    },
    CONFIRMED: {
        label: CONFIRMED_TAG_LABEL,
        colorTheme: 'success'
    }
}

export const modalityTags = {
    [ShiftModalityEnum.EXTERNAL]: {
        tagCode: ShiftModalityEnum.EXTERNAL,
        displayText: 'Livo',
        color: '#149ef2',
        icon: 'livo',
        backgroundColor: '#E0EFFF',
    },
    [ShiftModalityEnum.INTERNAL]: {
        tagCode: ShiftModalityEnum.INTERNAL,
        displayText: 'Interno',
        color: '#5fbdad',
        icon: 'internal-hospital',
        backgroundColor: '#eff7e7',
    }
}