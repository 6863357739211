import LivoIcon from "assets/LivoLogo"

interface InfoSectionProps {
    title: string;
    infoItems: {
        iconName: string;
        label: string;
        onClick?: () => void;
    }[]
}

export const InfoSection: React.FC<InfoSectionProps> = ({
    title,
    infoItems
}) => {
    return (
        <div
            className="flex flex-col p-medium rounded-[8px] bg-white space-y-medium w-full"
        >
            <p
                className="heading-sm text-Text-Subtle"
            >
                {title}
            </p>
            {
                infoItems.map((infoItem, index) => (
                    <div
                        onClick={infoItem.onClick}
                        key={index}
                        className={`flex space-x-small items-center ${infoItem.onClick? 'cursor-pointer' : ''}`}
                    >
                        <LivoIcon name={infoItem.iconName} size={24} color={'#ACBBCB'} />
                        <p
                            className="body-regular w-full"
                        >
                            {infoItem.label}
                        </p>
                        {
                            infoItem.onClick && (
                                <LivoIcon name='chevron-right' size={24} color={'#707A91'} />
                            )
                        }
                    </div>
    ))
}
        </div>
    )
}