import moment from "moment";
import { DateSelector } from "./DateSelector";
import { ShiftTimeInDaySelector } from "./ShiftTimeInDaySelector";
import { CalendarSummary, ShiftTimeConfigDTO } from "types/publish-shift";
import { ScheduleSelector } from "./ScheduleSelector";
import { SingleDateSelector } from "./SingleDateSelector";
import React, { useEffect } from "react";
import { RecurrentToggler } from "./RecurrentToggler";

interface DateTimeSelectorProps {
    selectedStartTime: string;
    selectedEndTime: string;
    setStartTime: (time: string) => void;
    setEndTime: (time: string) => void;
    shiftTimeInDayConfig: ShiftTimeConfigDTO;
    errorMessage?: string;
    recurrentDates: string[];
    setRecurrentDates: (dates: string[]) => void;
    subtitle?: string;
    isDisabled?: boolean;
    bulkSelectionEnabled?: boolean;
    calendarSummary: CalendarSummary[];
}
export const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
    selectedStartTime,
    selectedEndTime,
    setStartTime,
    setEndTime,
    shiftTimeInDayConfig,
    recurrentDates,
    setRecurrentDates,
    subtitle,
    isDisabled,
    bulkSelectionEnabled,
    calendarSummary
}) => {

    const [isRecurrent, setIsRecurrent] = React.useState(false);

    const changeDates = (datesSelected: string[]) => {
        // extract the first item from the array

        setRecurrentDates(datesSelected)
        // find the first date in time
        const date = datesSelected.sort((a, b) => moment(a).diff(moment(b)))[0]

        const newStartTime = moment(selectedStartTime).set(
            {
                year: moment(date).year(),
                month: moment(date).month(),
                date: moment(date).date()
            }
        )
        const newEndTime = moment(selectedEndTime).set(
            {
                year: moment(date).year(),
                month: moment(date).month(),
                date: moment(date).date()
            }
        )
        setStartTime(newStartTime.toISOString())
        setEndTime(newEndTime.toISOString())
    }

    const changeShiftTimeInDay = (shiftTimeInDay: string) => {
        const shiftTimeInDayConfigHashKey = shiftTimeInDay as keyof typeof shiftTimeInDayConfig;

        const newStartTime = moment(selectedStartTime).set(
            {
                hour: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].startTime.hour,
                minute: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].startTime.minute
            }
        )
        const newEndTime = moment(selectedEndTime).set(
            {
                hour: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].endTime.hour,
                minute: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].endTime.minute
            }
        )
        setStartTime(newStartTime.toISOString())
        setEndTime(newEndTime.toISOString())
    }

    const shiftTimeInDay = Object.keys(shiftTimeInDayConfig)
        .reverse()
        .find((key) => {
            const shiftTimeInDayConfigHashKey = key as keyof typeof shiftTimeInDayConfig;
            const config = shiftTimeInDayConfig[shiftTimeInDayConfigHashKey]
            const start = moment(selectedStartTime).set(
                {
                    hour: config.startTime.hour,
                    minute: config.startTime.minute
                }
            )
            const end = moment(selectedEndTime).set(
                {
                    hour: config.endTime.hour,
                    minute: config.endTime.minute
                }
            )
            return moment(selectedStartTime).isSameOrAfter(start)
        }) || 'dayShift'

    const handleSetTime = (time: string, setTimeDate: (timeDate: string) => void) => {
        const [hour, minute] = time.split(':');
        const newTime = moment(selectedStartTime).set({
            hour: parseInt(hour),
            minute: parseInt(minute),
        });
        setTimeDate(newTime.toISOString());
    }

    useEffect(() => {
        if (!isRecurrent) {
            changeDates([recurrentDates[0]])
        }
    }, [isRecurrent])

    return (
        <div
            className="flex flex-col self-start bg-white items-start space-y-medium justify-start "
        >
            {bulkSelectionEnabled ? <RecurrentToggler
                isRecurrent={isRecurrent}
                setIsRecurrent={setIsRecurrent}
            /> : null}
            <div>
                <div
                    style={{
                        opacity: isDisabled ? 0.5 : 1,
                        borderRadius: '8px',
                        pointerEvents: isDisabled ? 'none' : 'auto'
                    }}> {/* Adjust the opacity value as needed */}

                    {isRecurrent && bulkSelectionEnabled ? <DateSelector
                        setDates={changeDates}
                        selectedDates={recurrentDates}
                        calendarSummary={calendarSummary}
                    /> : <SingleDateSelector
                        selectedDate={recurrentDates[0]}
                        setDate={(date: string) => changeDates([date])}
                        calendarSummary={calendarSummary}
                    />
                    }
                </div>
                {subtitle ? <p
                    className="body-regular text-Text-Subtle"
                >
                    {subtitle}
                </p> : null
                }
            </div>

            <ShiftTimeInDaySelector
                setShiftTimeInDay={(shiftTimeInDay: string) => {
                    changeShiftTimeInDay(shiftTimeInDay)
                }}
                shiftTimeInDay={shiftTimeInDay}
            />
            <ScheduleSelector
                setStartTime={(time: string) => handleSetTime(time, setStartTime)}
                setEndTime={(time: string) => handleSetTime(time, setEndTime)}
                selectedStartTime={moment(selectedStartTime).format('HH:mm')}
                selectedEndTime={moment(selectedEndTime).format('HH:mm')}

            />
        </div>
    )
}