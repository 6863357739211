import { Modal } from "@mui/material";
import LivoIcon from "assets/LivoLogo";
import { ModalityTag } from "components/internal/ModalityTag";
import { ShiftModalityEnum } from "types";
import { ActionComponentIdEnum, Shift, ShiftTimeStatusEnum } from "types/shifts"
import { SHIFT_TIME_IN_DAY_DEFINITIONS, formatTime } from "utils";
import { ShiftCardTag } from "./ShiftCardTag";
import { ScheduleComponent } from "./ScheduleComponent";
import { ShiftCardContainer } from "./ShiftCardContainer";
import { ShiftCardHeader } from "components/shiftlist/ShiftCardHeader";
import { CapacityComponent } from "./CapacityComponent";

interface ShiftCardProps {
    shift: Shift;
    onClick?: () => void;
    isSelected?: boolean;
    actionComponents?: {
        iconName: string;
        onClick: (shift: Shift) => void;
        id: ActionComponentIdEnum
    }[];
}

export const ShiftCard: React.FC<ShiftCardProps> = ({
    shift,
    isSelected,
    onClick,
    actionComponents,
}) => {
    const shiftTimeInDay = SHIFT_TIME_IN_DAY_DEFINITIONS[shift.shiftTimeInDay]
    const cardColor = `bg-[${shiftTimeInDay.color}]`
    return (
        <ShiftCardContainer
            shift={shift}
            isSelected={isSelected}
            onClick={onClick}
            actionComponents={shift.shiftTimeStatus === ShiftTimeStatusEnum.PAST ? actionComponents?.filter(actionComponent => actionComponent.id !== ActionComponentIdEnum.EDIT) : actionComponents}
        >
            <div
                className=" flex flex-col p-small w-full"
            >
                <div
                    className="flex items-start flex-row justify-between w-full"
                >
                    <ShiftCardHeader
                        title={shift.title}
                        recurrent={shift.recurrentDates?.length > 1}
                        style={{
                            marginBottom: '4px'
                        }}
                        skills={shift.skills}
                        category={shift.category}
                        internalVisible={shift.internalVisible && shift.livoInternalOnboarded}
                        externalVisible={shift.externalVisible && shift.livoInternalOnboarded}
                    />

                    <ShiftCardTag
                        totalPendingClaims={shift.totalPendingClaims + shift.totalCancellationRequests}
                        isFilled={shift.totalAcceptedClaims === shift.capacity && shift.totalCancellationRequests === 0}
                    />
                </div>

                <div
                    className="flex items-center flex-row justify-between mt-small"
                >
                    <ScheduleComponent
                        startTime={shift.startTime}
                        finishTime={shift.finishTime}
                        style="body-sm"
                    />
                    <div
                        className="flex items-center justify-end"
                    >
                        <CapacityComponent
                            acceptedClaims={shift.totalAcceptedClaims}
                            emptyClaims={shift.capacity - shift.totalAcceptedClaims}
                        />
                    </div>
                </div>
            </div>
        </ShiftCardContainer>
    )
}