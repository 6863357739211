export const UNAVAILABLE_TAG_LABEL = 'Disponible próximamente';
export const IN_REVIEW_TAG_LABEL = 'Confirmación pendiente';
export const CONFIRMED_TAG_LABEL = 'Confirmado';
export const HEADER_TITLE = "Documentación de los turnos";
export const UPCOMING_SHIFTS = "Turnos pendientes";
export const PAST_SHIFTS = "Histórico";
export const CONFIRMED_SHIFTS = "Turnos confirmados"
export const PROFESSIONAL_TABLE_TITLE = "Profesional";
export const SPECIALIZATION_TABLE_TITLE = "Especialización";
export const PRICE_TABLE_TITLE = "Precio";
export const SHIFT_DAY_TABLE_TITLTE = "Día del turno";
export const FACILITY_NAME_TITLE = "Centro";
export const STATUS_TABLE_TITLE = "Estado";
export const SHIFT_CARD_TAG_FILLED = 'Cubierto';
export const SHIFT_CARD_PENDING_CLAIM_SINGLE = 'Solicitud';
export const SHIFT_CARD_PENDING_CLAIM_MULTIPLE = 'Solicitudes';