import { ShiftTimeInDayEnum } from "types/shifts";
export interface Shift {// to deprecate 

  id: number;
  startTime: string;
  finishTime: string;
  shiftTimeInDay: ShiftTimeInDayEnum;
  weekDayType: string;
  specialization: {
    name: string;
    translationDefault: string;
    translations: {
      [key: string]: string;
    };
  };
  details: string;
  hourRate: string;
  totalPay: string;
  currency: string;
  capacity: number;
  totalAcceptedClaims: number;
  totalClaims: number;
  totalPendingClaims: number;
}

export interface ProfessionalInitProfile {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  profilePictureUrl: string;
  licenseNumber: string;
  professionalCV: string;
  status: string;
  internal: boolean;
  email: string;
}

export interface ShiftClaim {
  shiftClaimId: number;
  shift: Shift;
  facilityName: string;
  professionalInitProfile: ProfessionalInitProfile;
  facilityReviewStatus: string;

}

export interface ProfessionalLegalProfile {
  socialSecurityNumber: string;
  address: string;
  bankAccountNumber: string;
  deductionPercentage: number;
  nationalIdUrl: string;
  certificateUrl: string;
  paymentReceiptUrl: string;
  noConvictionCertificateUrl: string;
  bankAccountDocumentUrl: string;
  incomeRetentionChangeRequestUrl: string;
  nationalId: string;
}

interface ShiftClaimsResponse {
  total: number;
  rows: ShiftClaim[];
}

export interface ShiftClaimDetails extends ShiftClaim {
  professionalLegalProfile: ProfessionalLegalProfile | undefined;
}

export enum ShiftModalityEnum {
  "INTERNAL" = "INTERNAL",
  "EXTERNAL" = "EXTERNAL"
}