import { useState } from "react";
import { InternalProfessionalFilter } from "../../services/api";
import { FacilityDataFieldDefinition, InternalProfessional, InvitationStatus } from "../../types/internal";
import { CircularProgress } from "@mui/material";
import { InternalProfessionalCard } from "./InternalProfessionalCard";
import { NO_PROFESSIONALS_SUBTITLE, NO_PROFESSIONALS_TITLE, NO_REQUESTS_TITLE, PROFESSIONALS_PAGE_TITLE, PROFESSIONAL_TABLE_NAME_HEADER } from "../../locales/internalProfessionalPage";
import { ProfessionalFilter } from "./ProfessionalFilter";
import { EmptyShiftsState } from "./EmptyState";
import InfiniteScroll from "react-infinite-scroll-component";


interface InternalProfessionalsListComponentProps {
    professionals: InternalProfessional[];
    loading: boolean;
    selectedFilter: InternalProfessionalFilter;
    selectedProfessionalId: number | null,
    setSelectedProfessionalId: (id: number) => void
    loadNextPage: () => void
    hasMoreData: boolean
    totalProfessionals: number
    pendingRequests: InternalProfessional[],
    dataFieldDefinitions: FacilityDataFieldDefinition[]
}

export const InternalProfessionalsListComponent: React.FC<InternalProfessionalsListComponentProps> = ({
    professionals,
    loading,
    selectedProfessionalId,
    setSelectedProfessionalId,
    loadNextPage,
    hasMoreData,
    totalProfessionals,
    pendingRequests,
    dataFieldDefinitions

}) => {
    const [invitationStatusFilter, setInvitationStatusFilter] = useState('all')

    const displayProfessionals = invitationStatusFilter === 'pending' ? pendingRequests : professionals

    return (
        <div
            className="flex flex-1 pt-[24px] pb-[24px] no-scrollbar justify-center overflow-y-hidden"

        >
            <div
                className="flex flex-col flex-1 w-full px-medium"
                style={{
                    maxWidth: '1280px'
                }}
            >

                <div
                    className="flex space-x-small items-center mb-large"
                >
                    <p
                        className="heading-md text-ellipsis text-nowrap"
                    >
                        {PROFESSIONALS_PAGE_TITLE}
                    </p>
                    <p
                        className="body-large text-Text-Subtle "
                    >
                        ({totalProfessionals})
                    </p>
                </div>
                <ProfessionalFilter
                    appliedFilter={invitationStatusFilter}
                    setFilter={(filter: any) => setInvitationStatusFilter(filter)}
                    nPendingClaims={pendingRequests.length}
                />
                <div
                    className="flex flex-col h-fit max-w-[1024px] w-full"
                >
                    <div
                        className="flex border-b-[1px] border-Divider-Default text-left mb-large px-small items-center py-small text-Text-Subtle space-x-small"
                    >
                        <div
                            className="flex-[2] flex table-header "
                        >
                            {PROFESSIONAL_TABLE_NAME_HEADER}
                        </div>
                        {
                            dataFieldDefinitions.map((dataField, index) =>
                                <div
                                    key={index}
                                    className="flex-1 flex table-header"
                                >
                                    {dataField.label}
                                </div>
                            )
                        }
                    </div>
                    {loading ? (
                        <div
                            className="flex flex-1 justify-center items-center h-fit"
                        >
                            <CircularProgress />
                        </div>
                    ) :
                    displayProfessionals.length === 0 ?
                            invitationStatusFilter === 'pending' ?
                                <EmptyShiftsState
                                    title={NO_REQUESTS_TITLE}
                                /> :
                                <EmptyShiftsState
                                    title={NO_PROFESSIONALS_TITLE}
                                    subtitle={NO_PROFESSIONALS_SUBTITLE} />
                            :

                            <div
                                id="scrollableDiv"
                                className="flex grow-1 flex-col  space-y-small w-full pb-100 no-scrollbar" 
                                style={{
                                    overflow: 'auto',
                                    display: 'flex',
                                    height: 'calc(100vh - 300px)',
                                  }}
                            >
                                <InfiniteScroll
                                    dataLength={displayProfessionals.length}
                                    next={() => loadNextPage()}
                                    hasMore={hasMoreData}
                                    loader={<div />}
                                    scrollableTarget="scrollableDiv"
                                    className="space-y-small"
                                    scrollThreshold={0.7}
                                    >
                                    {displayProfessionals.map((item) => (
                                        <InternalProfessionalCard
                                            key={item.id}
                                            professional={item}
                                            isSelected={selectedProfessionalId === item.id}
                                            onClick={() => setSelectedProfessionalId(item.id)}
                                            notifications={item.invitationStatus === InvitationStatus.PENDING ? 1 : undefined}
                                            dataFieldDefinitions={dataFieldDefinitions}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>

                    }
                    <div
                        className='h-large' />
                </div>
            </div>

        </div >
    )
}