

interface TagLabelProps {
    text: string;
    color?: string;
    style?: any;
    backgroundColor?: string;
}

export const TagLabel: React.FC<TagLabelProps> = ({ 
    text,
    color,
    backgroundColor='#EEEFF3',
    style }) => {
    return (
        <div
            className={`flex flex-row items-center px-[6px] py-[2px] rounded-[4px] ${style}`}
            style={{
                backgroundColor: backgroundColor,
                color: color
            }}
            >
            <p className="info-caption text-Text-Default">
                {text}
            </p>
        </div>
    );
}