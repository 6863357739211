import { ScheduleComponent } from "components/shifts/ScheduleComponent";
import { ShiftCardContainer } from "components/shifts/ShiftCardContainer";
import { ShiftCardTag } from "components/shifts/ShiftCardTag";
import moment from "moment";
import { Shift } from "types/shifts"
import { SHIFT_TIME_IN_DAY_DEFINITIONS, formatTime } from "utils";

interface ActivityShiftCardProps {
    shift: Shift;
    onClick?: () => void;
    isSelected?: boolean;
}

export const ActivityShiftCard: React.FC<ActivityShiftCardProps> = ({
    shift,
    isSelected,
    onClick
}) => {
    const shiftTimeInDay = SHIFT_TIME_IN_DAY_DEFINITIONS[shift.shiftTimeInDay]
    return (
        <ShiftCardContainer
            shift={shift}
            isSelected={isSelected}
            onClick={onClick}
        >
            <div
                className=" flex flex-col p-small w-full"
            >
                <div
                    className="flex items-center flex-row justify-between w-full"
                >
                    <h1
                        className="subtitle-regular mb-tiny mt-[4px]"
                    >
                        {shift.specialization.translations.es}
                    </h1>
                    <ShiftCardTag
                        totalPendingClaims={shift.totalPendingClaims + shift.totalCancellationRequests}
                        isFilled={shift.totalAcceptedClaims === shift.capacity && shift.totalCancellationRequests === 0}
                        isSelected={isSelected}
                    />
                </div>
                <div
                    className="flex items-center flex-row justify-between mt-small"
                >
                    <ScheduleComponent
                        startTime={shift.startTime}
                        finishTime={shift.finishTime}
                        style="body-sm"
                    />
                    <div
                        className="flex items-center flex-row justify-start"
                    >
                        <p
                            className="body-sm"
                        >
                            {moment(shift.startTime).format('DD/MM/YYYY')}

                        </p>
                    </div>
                </div>
            </div>
        </ShiftCardContainer>
    )
}