import { Avatar } from "assets/Avatar";
import LivoIcon from "assets/LivoLogo";
import { ModalityTag } from "components/internal/ModalityTag";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ShiftModalityEnum } from "types";
import { modalityTags } from "utils/constants";

interface ProfilePictureProps {
    profilePictureUrl?: string;
    modality: ShiftModalityEnum | null;
    style?: any;
    size: number;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ profilePictureUrl, modality, size, style }) => {
    const {accountInfo} = useSelector((state: RootState) => state.account);
    return (
        <div className="flex items-center relative">

            <div className={`flex rounded-[8px]] `}
                style={{ width: size, height: size }}
            >
                {profilePictureUrl ? <img src={profilePictureUrl}
                    className="rounded-[8px] object-cover" /> :
                    <Avatar size={size} />
                }   
            </div>

            {modality && accountInfo?.livoInternalOnboarded &&
                <div
                    className={`absolute right-[-4px] -bottom-tiny flex items-center justify-center p-[2px] rounded-[100px] border-solid border-[2px]  border-white ${style} `}
                    style={{ backgroundColor: modalityTags[modality].backgroundColor }}
                >
                    <LivoIcon name={modalityTags[modality].icon} size={size / 3} color={modalityTags[modality].color} />
                </div>
            }
        </div>

    )
}