import moment from "moment"
import { CalendarSummaryAction } from "store/types"

const initialState = {
    selectedDate: moment().format('YYYY-MM-DD'),
    selectedShiftId: null
}

const calendarSummaryReducer = (state = initialState, action: CalendarSummaryAction) => {
    switch (action.type) {
        case 'SET_SELECTED_DATE': 
            return {
                ...state,
                selectedDate: action.payload
            }
        case 'SET_SELECTED_SHIFT_ID':// migrate to SET_CALENDAR_SELECTED_SHIFT_ID for clarity
            return {
                ...state,
                selectedShiftId: action.payload
            }
        default:
            return state
    }
}

export default calendarSummaryReducer;