import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ADDRESS_COPIED, ADDRESS_LABEL, BANK_ACCOUNT_NUMBER_COPIED, BANK_ACCOUNT_NUMBER_LABEL, CONTACT_INFORMATION_LABEL, COPY_ALL_FIELDS, copyProfessionalFields, DEDUCTION_PERCENTAGE_COPIED, DEDUCTION_PERCENTAGE_LABEL, EMAIL_COPIED, EMAIL_LABEL, LEGAL_DOCUMENTATION_TITLE, LEGAL_INFORMATION_TITLE, LICENSE_NUMBER_LABEL, NATIONAL_ID, NATIONAL_ID_COPIED, PHONE_NUMBER_COPIED, PHONE_NUMBER_LABEL, PROFESSIONAL_PROFILE_TITLE, SOCIAL_SECURITY_NUMBER_COPIED, SOCIAL_SECURITY_NUMBER_LABEL, UNAVAILABLE_LEGAL_INFORMATION } from 'locales/shiftClaimDetails/es';
import { ProfessionalInitProfile, ProfessionalLegalProfile } from 'types';
import { Avatar, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { CopyButton } from './common/CopyButton';
import LivoIcon from 'assets/LivoLogo';
import { showToastAction } from 'store/actions/appConfigurationActions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';

export default function ProfessionalProfileCard({ professionalInitProfile, professionalLegalProfile }: { professionalInitProfile: ProfessionalInitProfile, professionalLegalProfile?: ProfessionalLegalProfile }) {
  const dispatch = useDispatch<AppDispatch>()

  const BoxRow = ({ children }: { children: React.ReactNode }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {children}
    </Box>
  )
  const legalDocumentationNotAvailable = (
    <Typography variant='body2' color='text.secondary' gutterBottom>
      {UNAVAILABLE_LEGAL_INFORMATION}
    </Typography>
  )

  const fieldsToCopy = copyProfessionalFields(professionalInitProfile, professionalLegalProfile);
  const legalDocumentation = professionalLegalProfile && (
    <>
      <Typography variant="h5" sx={{ mb: 2, fontSize: 20 }}>
        {LEGAL_INFORMATION_TITLE}
      </Typography>
      <BoxRow>
        <Typography variant='body1' sx={{ mr: 1 }}>
          {NATIONAL_ID}:
        </Typography>
        <Typography color="text.secondary" variant='body2'>
          {professionalLegalProfile.nationalId}
        </Typography>
        {professionalLegalProfile.nationalId && <CopyButton text={professionalLegalProfile.nationalId}
          successMessage={NATIONAL_ID_COPIED}
          style={{ marginLeft: '8px' }} />}
      </BoxRow>
      <BoxRow>
        <Typography variant="body1" sx={{ mr: 1 }}>
          {ADDRESS_LABEL}:
        </Typography>
        <Typography color="text.secondary" variant='body2'>
          {professionalLegalProfile.address}
        </Typography>
        {professionalLegalProfile.address && <CopyButton
          successMessage={ADDRESS_COPIED}
          text={professionalLegalProfile.address} style={{ marginLeft: '8px' }} />}
      </BoxRow>
      <BoxRow>
        <Typography variant="body1" sx={{ mr: 1 }}>
          {BANK_ACCOUNT_NUMBER_LABEL}:
        </Typography>
        <Typography color="text.secondary" variant='body2'>
          {professionalLegalProfile.bankAccountNumber}
        </Typography>
        {professionalLegalProfile.bankAccountNumber && <CopyButton
          successMessage={BANK_ACCOUNT_NUMBER_COPIED}
          text={professionalLegalProfile.bankAccountNumber} style={{ marginLeft: '8px' }} />}
      </BoxRow>
      <BoxRow>
        <Typography variant="body1" sx={{ mr: 1 }}>
          {DEDUCTION_PERCENTAGE_LABEL}:
        </Typography>
        <Typography color="text.secondary" variant='body2'>
          {professionalLegalProfile.deductionPercentage}%
        </Typography>
        {professionalLegalProfile.deductionPercentage && <CopyButton
          successMessage={DEDUCTION_PERCENTAGE_COPIED}
          text={professionalLegalProfile.deductionPercentage.toString() + '%'} style={{ marginLeft: '8px' }} />}
      </BoxRow>
      <BoxRow>
        <Typography variant="body1" sx={{ mr: 1 }}>
          {SOCIAL_SECURITY_NUMBER_LABEL}:
        </Typography>
        <Typography color="text.secondary" variant='body2'>
          {professionalLegalProfile.socialSecurityNumber}
        </Typography>
       { professionalLegalProfile.socialSecurityNumber && <CopyButton
          successMessage={SOCIAL_SECURITY_NUMBER_COPIED}
          text={professionalLegalProfile.socialSecurityNumber} style={{ marginLeft: '8px' }} />}
      </BoxRow>
    </>
  )
  return (
    <Card sx={{ m: 1, flex: 1, minWidth: 400 }}>
      <CardContent>
        <div
          className='justify-between flex flex-row items-center'
        >
          <Typography sx={{ fontSize: 14, mb: 2 }} color="text.secondary" gutterBottom>
            {PROFESSIONAL_PROFILE_TITLE}
          </Typography>
          <button
            onClick={() => {
              navigator.clipboard.writeText(fieldsToCopy);
              dispatch(showToastAction({
                message: 'Información copiada con éxito',
                severity: 'success'
              }))
            }}
            className='flex flex-row items-center py-small pr-large pl-medium ring-1 ring-solid ring-[#848DA9] rounded-[100px]'
          >
            <p
              className='action-regular text-Primary-500 mr-tiny'
            >
              {COPY_ALL_FIELDS}
            </p>
            <LivoIcon name={'copy'} size={16} color={'#149EF2'} />
          </button>
        </div>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={professionalInitProfile.profilePictureUrl}
              sx={{ width: 70, height: 70, marginBottom: 2, mr: 2 }}
            >
              {professionalInitProfile.profilePictureUrl ? null : <PersonIcon />}
            </Avatar>
            <Box>
              <Typography variant="h5" component="div" gutterBottom>
                {professionalInitProfile.firstName} {professionalInitProfile.lastName}
              </Typography>
              <BoxRow>
                <Typography variant='body1' sx={{ mr: 1 }}>
                  {LICENSE_NUMBER_LABEL}:
                </Typography>
                <Typography color="text.secondary" variant='body2'>
                  {professionalInitProfile.licenseNumber}
                </Typography>
              </BoxRow>
            </Box>

          </Box>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 2, fontSize: 20 }}>
            {CONTACT_INFORMATION_LABEL}
          </Typography>
          <BoxRow>
            <Typography variant="body1" component="div">
              <b>{EMAIL_LABEL}:</b> {professionalInitProfile.email}
            </Typography>
            {professionalInitProfile.email && <CopyButton
              successMessage={EMAIL_COPIED}
              text={professionalInitProfile.email} style={{ marginLeft: '8px' }} />}
          </BoxRow>
          <BoxRow>
            <Typography variant="body1" component="div">
              <b>{PHONE_NUMBER_LABEL}:</b> {professionalInitProfile.phoneNumber}
            </Typography>
            {professionalInitProfile.phoneNumber && <CopyButton
              successMessage={PHONE_NUMBER_COPIED}
              text={professionalInitProfile.phoneNumber} style={{ marginLeft: '8px' }} />}
          </BoxRow>

        </Box>
        {professionalLegalProfile ? legalDocumentation : legalDocumentationNotAvailable}
      </CardContent>
    </Card>
  );
}