import React, { useEffect, useRef, useState } from "react";
import { ModalityTag } from "components/internal/ModalityTag";
import { ScheduleComponent } from "components/shifts/ScheduleComponent";
import { ShiftCardContainer } from "components/shifts/ShiftCardContainer";
import { ShiftCardTag } from "components/shifts/ShiftCardTag";
import { ShiftModalityEnum } from "types";
import { ActionComponentIdEnum, Shift, ShiftTimeStatusEnum } from "types/shifts";
import { CapacityComponent } from "components/shifts/CapacityComponent";
import { TagLabel } from "components/common/TagLabel";
import { ShiftCard } from "components/shifts/ShiftCard";
import { LargeCardHeader } from "./LargeCardHeader";

interface ShiftListCardProps {
    shift: Shift;
    isSelected?: boolean;
    onClick?: () => void;
    actionComponents?: {
        iconName: string;
        onClick: (shift: Shift) => void;
        id: ActionComponentIdEnum;
    }[];
}

export const ShiftListCard: React.FC<ShiftListCardProps> = ({
    shift,
    isSelected,
    onClick,
    actionComponents
}) => {
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;

        const handleResize = () => {
            if (container) {
                setIsLargeScreen(container.offsetWidth > 640);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (container) {
            resizeObserver.observe(container);
            handleResize(); // Initial check
        }

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
        };
    }, []);

    const largeCard = (
        <div className="flex flex-1 items-center justify-between p-small py-large space-x-small w-full">
            <div className="flex flex-1 text-left w-[180px]">
                <LargeCardHeader
                    title={shift.title}
                    recurrent={shift.recurrentDates?.length > 1}
                    category={shift.category}
                    internalVisible={shift.internalVisible && shift.livoInternalOnboarded}
                    externalVisible={shift.externalVisible && shift.livoInternalOnboarded}
                />
            </div>
            <div className="flex flex-1 items-center flex-row justify-start w-full space-x-tiny">
                {shift.livoInternalOnboarded && (
                    <div
                        className="flex items-center space-x-tiny"
                    >
                        <ModalityTag
                            modality={ShiftModalityEnum.INTERNAL}
                            style={!shift.internalVisible ? 'opacity-0' : isSelected ? 'mr-tiny bg-Secondary-050' : 'mr-tiny'}
                            shortTag={true}
                        />
                        <ModalityTag
                            modality={ShiftModalityEnum.EXTERNAL}
                            style={!shift.externalVisible ? 'opacity-0' : isSelected ? 'bg-[#E0EFFF]' : ''}
                            shortTag={true}
                        />
                    </div>
                )}
                {shift.skills.map(skill =>
                    <TagLabel
                        text={skill.displayText}
                    />)
                }
            </div>
            <div className="flex flex-1 items-center flex-row justify-between space-x-small">


                <div className="flex flex-1 w-full justify-center items-center">
                    <ScheduleComponent
                        startTime={shift.startTime}
                        finishTime={shift.finishTime}
                        style="body-sm"
                    />
                </div>
                <div className="flex flex-1 items-end justify-end w-full space-x-medium">
                    <div
                        className="flex items-center justify-end"
                    >
                        <CapacityComponent
                            acceptedClaims={shift.totalAcceptedClaims}
                            emptyClaims={shift.capacity - shift.totalAcceptedClaims}
                        />
                    </div>
                    <ShiftCardTag
                        totalPendingClaims={shift.totalPendingClaims + shift.totalCancellationRequests}
                        isFilled={shift.totalAcceptedClaims === shift.capacity && shift.totalCancellationRequests === 0}
                        largeTag={true}
                    />
                </div>
            </div>
        </div>
    );


    return <div
        ref={containerRef}
        className="w-full"
    >
        {isLargeScreen ? (
            <ShiftCardContainer
                shift={shift}
                isSelected={isSelected}
                onClick={onClick}
                actionComponents={shift.shiftTimeStatus === ShiftTimeStatusEnum.PAST ? actionComponents?.filter(actionComponent => actionComponent.id !== ActionComponentIdEnum.EDIT) : actionComponents}
            >
                {largeCard}
            </ShiftCardContainer>
        ) :

            <ShiftCard
                shift={shift}
                isSelected={isSelected}
                onClick={onClick}
                actionComponents={shift.shiftTimeStatus === ShiftTimeStatusEnum.PAST ? actionComponents?.filter(actionComponent => actionComponent.id !== ActionComponentIdEnum.EDIT) : actionComponents}
            />
        }
    </div>

};
