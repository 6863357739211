import { ClaimRequest } from "types/claims"
import { ClaimRow } from "./ClaimRow"
import { PENDING_CLAIMS_TITLE, PROFESSIONALS_LABEL, REJECT_PROFESSIONAL_MODAL_TITLE } from "locales/shiftClaimDetails/es";
import { ShiftCardTag } from "components/shifts/ShiftCardTag";
import { AcceptButton } from "./AcceptButton";
import { RejectButton } from "./RejectButton";
import { RejectProfessionalReasonsModal } from "./RejectProfessionalReasonsModal";
import { shiftClaimAccept, shiftClaimReject } from "services/claims";
import { useState } from "react";

interface PendingClaimsComponentProps {
    claims: ClaimRequest[];
    onAccept: (claimId: ClaimRequest) => void;
    onReject: (claimId: number) => void;
    selectClaim: (claim: ClaimRequest) => void
}

export const PendingClaimsComponent: React.FC<PendingClaimsComponentProps> = ({
    claims,
    onAccept,
    onReject,
    selectClaim
}) => {


    return (
        <div
            className="flex flex-col w-full min-w-fit"
        >
            <div
                className="flex items-center space-x-tiny mb-small"
            >
                <p
                    className="heading-md"
                >
                    {PENDING_CLAIMS_TITLE}
                </p>
                <ShiftCardTag
                    isFilled={false}
                    totalPendingClaims={claims.length}
                />
            </div>

            <div
                className="flex flex-col w-full flex-1 space-y-small justify-start"
            >
                {
                    claims.map((claim, index) => (
                        <div
                            className="flex w-full items-center justify-between space-x-small"
                        >
                            <ClaimRow
                                key={index}
                                claim={claim}
                                onClick={() => selectClaim(claim)}
                            />
                            <div
                                className="flex flex-row items-center justify-center space-x-small"
                            >
                                <AcceptButton
                                    onClick={() => {
                                            onAccept(claim)
                                    }}
                                    isDisabled={false}
                                />
                                <RejectButton
                                    onClick={() => {
                                        onReject(claim.id)
                                    }}
                                    isDisabled={false}
                                />
                            </div>
                        </div>
                    ))
                }

            </div>
            
        </div>
    )
}