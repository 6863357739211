import { SingleSelectModal } from "../common/SingleSelectModal";
import { useState } from "react";
import { DataFieldOption, FacilityDataFieldDefinition } from "../../types/internal";
import { MultiSelectModal } from "../../components/common/MultiSelectModal";
import LivoIcon from "assets/LivoLogo";


interface MultiSelectDataFieldProps {
    dataFieldDefinition: FacilityDataFieldDefinition;
    selectedValues: string[];
    setSelectedValues: (selectedValues: string[]) => void;
    title: string;
    hasChanged?: boolean;
}

export const MultiSelectDataField: React.FC<MultiSelectDataFieldProps> = ({
    dataFieldDefinition,
    selectedValues,
    setSelectedValues,
    title,
    hasChanged
}) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
        <div
            className="w-full"
        >

            <button
                onClick={() => setModalVisible(true)}
                className="w-full"
            >
                <div
                    className="py-medium px-small flex rounded-[8px] ring-solif ring-[1px] ring-Divider-Default w-full text-left space-x-small items-center"
                >
                    {hasChanged ? <div
                        className="w-small h-small bg-Action-Primary rounded-full"
                    /> : null}
                    <div
                        className="flex-1 flex-shrink-1 mr-tiny overflow-hidden"
                    >
                        <p
                            className="body-regular overflow-hidden whitespace-nowrap text-ellipsis flex-1 truncate"
                        >
                            {
                                selectedValues.length > 0 ? dataFieldDefinition.options.filter((option: DataFieldOption) => selectedValues.includes(option.value))
                                    .map((option: DataFieldOption) => option.displayText).join(', ') : '-'}
                        </p>
                    </div>
                    {/* <LivoIcon name="chevron-down" color="#2C3038" size={24} /> */}

                </div>
            </button>
            <MultiSelectModal
                onSubmit={(selectedOptions: string[]) => {
                    setSelectedValues(selectedOptions)
                    setModalVisible(false)
                }}
                isOpen={modalVisible}
                title={title}
                options={dataFieldDefinition.options.map((option: DataFieldOption) => ({
                    label: option.displayText,
                    value: option.value
                }))}
                initialSelection={selectedValues}
                goBack={() => setModalVisible(false)}
            />

        </div>
    )
}