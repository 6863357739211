import React, { ReactNode, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Button, CircularProgress, BoxProps, CardContent, Card, Container, IconButton } from '@mui/material';
import { confirmRequest, fetchContactInfo, fetchLegalReviewDetails } from 'services/api';
import { ShiftClaimDetails } from 'types';
import '../styles/ShiftClaimDetails.css';
import { CONFIRMATION_ERROR_MESSAGE, CONFIRMATION_SUCCESS_MESSAGE, CONFIRM_BUTTON_LABEL, CONTACT_INFO, CONTACT_LABEL, ERROR_CONTACT_INFO, PAGE_TITLE, UNAVAILABLE_SHIFT_MESSAGE } from 'locales/shiftClaimDetails/es';
import { reviewStatusLabelProps, FacilityReviewStatusEnum } from 'utils/constants';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ProfessionalProfileCard from 'components/ProfessionalProfileCard';
import ShiftInformationCard from 'components/ShiftInformationCard';
import LegalFilesCard from 'components/LegalFilesCard';
import { ArrowBack } from '@mui/icons-material';

export const ShiftClaimDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [shiftClaimDetails, setShiftClaimDetails] = useState<ShiftClaimDetails | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'success'>('success'); // You can set this to 'error' for error messages
  const [email, setEmail] = useState('')
  const handleGoBack = () => {
    window.location.href = '/documentacion';
  };
  const fetchShiftClaimDetails = async () => {
    setLoading(true);
    await fetchContactInfo()
      .then((contactInfo) => setEmail(contactInfo.email))
      .catch((error) => openSnackbar(ERROR_CONTACT_INFO, 'error'))
    await fetchLegalReviewDetails(+id!!)
      .then((shiftClaimDetails) => {
        setShiftClaimDetails(shiftClaimDetails);
        setLoading(false);
      })
      .catch((error) => {
        setShiftClaimDetails(null);
        setLoading(false);
      });
  };

  const openSnackbar = (message: string, severity: 'error' | 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleConfirmClick = async () => {
    confirmRequest(shiftClaimDetails!!.shiftClaimId)
      .then(() => {
        openSnackbar(CONFIRMATION_SUCCESS_MESSAGE, 'success')
        fetchShiftClaimDetails()
      })
      .catch(() => openSnackbar(CONFIRMATION_ERROR_MESSAGE, 'error'))
  }

  useEffect(() => {
    fetchShiftClaimDetails();
  }, []);

  if (loading) {
    return (
      <Container sx={{ display: 'flex', flex: 1, alignItems: 'center', minHeight: '30em', minWidth: '100%', justifyContent: 'center', }}>
        <CircularProgress />
      </Container>
    );
  }


  const statusLabelProp = reviewStatusLabelProps[shiftClaimDetails?.facilityReviewStatus as keyof typeof reviewStatusLabelProps]

  interface BoxComponentProps extends BoxProps {
    children: ReactNode;
  }
  const BoxComponent: React.FC<BoxComponentProps> = ({ children, ...props }) => (
    <Box sx={{ mb: 2 }} {...props}>
      {children}
    </Box>
  )

  const shiftClaimSubtitle = shiftClaimDetails !== null ? ` - ${statusLabelProp.label}` : '';
  const title = PAGE_TITLE + shiftClaimSubtitle;

  const shiftClaimDetailsComponent = shiftClaimDetails !== null ? (
    <>
      <Box sx={{ flexDirection: 'column', display: 'flex', mb: 3 }}>
        <ShiftInformationCard shift={shiftClaimDetails!!.shift} facilityName={shiftClaimDetails.facilityName} />
        <Box sx={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
          <ProfessionalProfileCard professionalInitProfile={shiftClaimDetails!!.professionalInitProfile} professionalLegalProfile={shiftClaimDetails!!.professionalLegalProfile} />
          <LegalFilesCard
            professionalInitProfile={shiftClaimDetails!!.professionalInitProfile}
            professionalLegalProfile={shiftClaimDetails!!.professionalLegalProfile}
            shiftClaimId={shiftClaimDetails!!.shiftClaimId}
            />
        </Box>
        {email && <Card sx={{ m: 1 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              {CONTACT_LABEL}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {CONTACT_INFO}&nbsp;
              <a href={`mailto:${email}`}>{email}</a>
            </Typography>
          </CardContent>
        </Card>}
      </Box>
      <BoxComponent sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
        {shiftClaimDetails?.facilityReviewStatus === FacilityReviewStatusEnum.IN_REVIEW &&
          <Button color='success' variant='contained' onClick={handleConfirmClick} size='medium'>
            {CONFIRM_BUTTON_LABEL}
          </Button>}
      </BoxComponent>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  ) : (
    <Card>
      <CardContent>
        <Typography variant='body1' color='text.secondary' gutterBottom>
          {UNAVAILABLE_SHIFT_MESSAGE}
        </Typography>
      </CardContent>
    </Card>
  )

  return (
    <div
      className="flex content overflow-auto"
    >
      <Container sx={{ pb: 2, pt: 2, height: '100vh' }}>
        <Box sx={{ mt: 2, mb: 2, alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
          <IconButton onClick={handleGoBack} size="large" sx={{ mr: 1 }}>
            <ArrowBack fontSize="inherit" />
          </IconButton>
          <Typography variant="h4">
            {title}
          </Typography>
        </Box>
        {shiftClaimDetailsComponent}
      </Container>
    </div>


  );
};