import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { SHIFT_INFORMATION_TITLE, TOTAL_PAY_LABEL } from 'locales/shiftClaimDetails/es';
import { Shift } from 'types';
import { formatDate, formatSchedule } from 'utils';

export default function ShiftInformationCard({ shift, facilityName }: { shift: Shift, facilityName: string }) {
    return (
        <Card sx={{m: 1}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {SHIFT_INFORMATION_TITLE}
                </Typography>
                {facilityName && <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {facilityName}
                </Typography>}
                <Typography variant="h5" component="div">
                    {formatDate(shift.startTime)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {formatSchedule(shift.startTime, shift.finishTime)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {shift.specialization.translations.es}
                </Typography>
                <Typography variant="body2" sx={{mb: 1.5}}>
                    <br />
                    {TOTAL_PAY_LABEL}: {shift.totalPay}€
                </Typography>
            </CardContent>
        </Card>
    );
}