import LivoIcon from "assets/LivoLogo";
import { useEffect, useState } from "react";

interface PublishShiftInputProps {
    setValue: (time: string) => void;
    selectedValue: string;
    placeHolder?: string;
    errorMessage?: string;
    endingLabel?: string;
    inputType?: string;
    disabled?: boolean;
}


export const PublishShiftInput: React.FC<PublishShiftInputProps> = ({
    setValue,
    selectedValue,
    placeHolder,
    errorMessage,
    endingLabel,
    inputType,
    disabled
}) => {
    const [inputValue, setInputValue] = useState(selectedValue);

    useEffect(() => {
        setInputValue(selectedValue);
    }, [selectedValue]);

    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
        setValue(inputValue);
    }

    return (
        <div
            className="flex flex-col"
        >
            <div
                className={`flex flex-row box-border ring-[1px] ring-solid rounded-[8px] px-small py-medium w-full space-x-small justify-start items-center ${!disabled ? 'bg-white' : 'bg-Background-Secondary'} flex-shrink-1 
                        ${errorMessage ? 'ring-[2px] ring-red-500' : 'ring-Divider-Subtle focus-within:ring-Action-Secondary focus-within:ring-[2px]'} `}

            >
                <input
                    disabled={disabled}
                    type={inputType ? inputType : "text"}
                    value={disabled ? '' : inputValue}
                    onChange={handleInputValue}
                    onBlur={handleInputBlur}
                    className="body-regular placeholder-Text-Subtle focus:outline-none placeholder:body-regular placeholder:text-Text-Subtle w-full bg-transparent"
                    placeholder={placeHolder}
                />
                {
                    endingLabel ? (
                        <p className="body-regular text-Text-Subtle ml-small">
                            {endingLabel}
                        </p>
                    ) : null

                }
            </div>
            {
                errorMessage ? (
                    <p className="info-caption mt-tiny text-Negative-500">
                        {errorMessage}
                    </p>
                ) : null
            }
        </div>

    );
};
