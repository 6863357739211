import LivoIcon from "assets/LivoLogo";
import { CategoryTag } from "components/common/CategoryTag";
import { RECURRENT_LABEL } from "locales/publishShift";
import { DELETE_SHIFT_LABEL, DUPLICATE_SHIFT_LABEL, EDIT_SHIFT_LABEL } from "locales/shiftClaimDetails/es";
import { useEffect, useRef, useState } from "react";

interface ShiftDetailsHeaderProps {
    title: string;
    onClose: () => void;
    onEdit: () => void;
    onDelete: () => void;
    onCopy: () => void;
    editable?: boolean;
    recurrent?: boolean;
}

export const ShiftDetailsHeader: React.FC<ShiftDetailsHeaderProps> = ({
    title,
    onClose,
    onEdit,
    onDelete,
    editable,
    onCopy,
    recurrent
}) => {
    const [editDeleteModalOpen, setEditDeleteModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setEditDeleteModalOpen(false);
            }
        };

        if (editDeleteModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editDeleteModalOpen]);

    return (
        <div className="flex items-center w-full px-large justify-between">
            <button onClick={onClose}>
                <LivoIcon name='close' size={24} color='#707A91' />
            </button>
            {
                recurrent ?
                    <div
                        className="flex items-center space-x-small"
                    >
                        <LivoIcon name='repeat' size={16} color='#ACBBCB' />
                        <p
                            className="body-sm text-Text-Subtle"
                        >
                            {RECURRENT_LABEL}
                        </p>
                    </div> : null
            }
            <div className="relative">
                <button
                    ref={buttonRef}
                    onClick={(e) => {
                        e.preventDefault();
                        setEditDeleteModalOpen(!editDeleteModalOpen);
                    }}
                >
                    <LivoIcon name='dots' size={24} color='#707A91' />
                </button>
                {editDeleteModalOpen && (
                    <div
                        ref={modalRef}
                        className="p-medium rounded-[8px] mb-[14px] absolute z-10 bg-white shadow-custom right-full top-0 mr-tiny w-[255px] space-y-[14px]"
                    >
                        <button
                            className="pv-tiny px-small flex w-full justify-between items-center"
                            onClick={onCopy}
                        >
                            <p className="body-regular text-Text-Subtle mr-small">
                                {DUPLICATE_SHIFT_LABEL}
                            </p>
                            <LivoIcon name='copy' size={24} color='#707A91' />
                        </button>
                        {
                            editable ?
                                <>
                                    <button
                                        className="pv-tiny px-small flex w-full justify-between items-center"
                                        onClick={onEdit}
                                    >
                                        <p className="body-regular text-Text-Subtle mr-small">
                                            {EDIT_SHIFT_LABEL}
                                        </p>
                                        <LivoIcon name='pencil' size={24} color='#707A91' />
                                    </button>
                                    <button
                                        className="pv-tiny px-small flex w-full justify-between items-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onDelete();
                                        }}
                                    >
                                        <p className="body-regular text-Negative-500 mr-small">
                                            {DELETE_SHIFT_LABEL}
                                        </p>
                                        <LivoIcon name='trash' size={24} color='#FA3D3B' />
                                    </button>
                                </>
                                : null
                        }
                    </div>
                )}
            </div>
        </div>
    );
};
