import { ClaimRequest } from "../../../types/claims"
import { AcceptButton } from "../AcceptButton"
import { ClaimRow } from "../ClaimRow"
import { EmptyCapacityClaim } from "../EmptyCapacityClaim"
import { IncreaseCapacity } from "../IncreaseCapacityItem"
import { RejectButton } from "../RejectButton"
import { AcceptedClaimRow } from "./AcceptedClaimRow"

interface AcceptedProfessionalsListProps {
    claims: ClaimRequest[];
    selectClaim: (claim: ClaimRequest) => void;
    acceptCancellationRequest: (claimId: number) => void;
    rejectCancellationRequest: (claimId: number) => void;
    capacity: number;
    editable: boolean;
    onDecreaseCapacity: () => void;
    setCapacityModalOpen: (isOpen: boolean) => void;
}

export const AcceptedProfessionalsList: React.FC<AcceptedProfessionalsListProps> = ({
    claims,
    selectClaim,
    acceptCancellationRequest,
    rejectCancellationRequest,
    capacity,
    editable,
    onDecreaseCapacity,
    setCapacityModalOpen
}) => {
    return (
        <div
            className="flex flex-col flex-1 space-y-small"
        >
            {
                claims.map((claim, index) => (

                    <div
                        className="flex w-full items-center justify-between space-x-small flex-wrap"
                    >
                        <AcceptedClaimRow
                            key={index}
                            claim={claim}
                            onClick={() => selectClaim(claim)}
                        />
                        {claim.cancellationRequest ? <div
                            className="flex flex-row items-center justify-center space-x-small"
                        >
                            <AcceptButton
                                onClick={() => {
                                    acceptCancellationRequest(claim.id)
                                }}
                                isDisabled={false}
                            />
                            <RejectButton
                                onClick={() => {
                                    rejectCancellationRequest(claim.id)
                                }}
                                isDisabled={false}
                            />
                        </div> : null}
                    </div>

                ))
            }
            {
                Array.from({ length: capacity - claims.length }).map((_, index) => (
                    <EmptyCapacityClaim
                        key={index}
                        onClick={() => onDecreaseCapacity()}
                        editable={editable && (capacity + claims.length) > 1}
                    />
                ))
            }
            {editable ? <IncreaseCapacity
                onClick={() => {
                    setCapacityModalOpen(true)
                }}
            /> : null}
        </div>
    )
}