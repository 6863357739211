import LivoIcon from "assets/LivoLogo"
import { COMPLETED_SHIFTS, FACILITY_EXPERIENCE, FIRST_SHIFTER_LABEL, IN, PROFESSIONAL_DATA_TITLE } from "locales/professionalClaim"
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ProfessionalProfile } from "types/claims"

interface ProfessionalFacilityExperienceProps {
    professionalProfile: ProfessionalProfile;
}

export const ProfessionalFacilityExperience: React.FC<ProfessionalFacilityExperienceProps> = ({
    professionalProfile

}) => {
    const account = useSelector((state: RootState) => state.account)
    return (
        <div
            className="flex flex-col w-full p-medium"
        >
            <div
                className="flex flex-row space-x-small mb-large"
            >
                <LivoIcon name='report-medical' size={24} color='#ACBBCB' />
                <p
                    className="body-regular"
                >
                    {FACILITY_EXPERIENCE}
                </p>
            </div>
            <div
                className="flex flex-row w-full py-medium px-small rounded-[8px] bg-white ring-solid ring-[1px] ring-Divider-Subtle items-center"
            >
                <div
                    className="flex flex-col w-full mr-small"
                >
                    <p
                        className="subtitle-regular"
                    >
                        {COMPLETED_SHIFTS}
                    </p>
                   {account.accountInfo && <p
                        className="body-regular text-Text-Subtle"
                    >
                        {IN} {account.accountInfo.facility.name}
                    </p>}
                </div>
                <div
                    className="flex"
                >
                    {professionalProfile.firstShifterForFacility ?
                        <p
                            className="heading-sm text-[#987ED4] text-nowrap"
                        >
                            {FIRST_SHIFTER_LABEL}
                        </p> :

                        <p
                            className="heading-sm"
                        >
                            {professionalProfile.totalShiftsInFacility.totalShiftsInFacility}
                        </p>

                    }
                </div>
            </div>
        </div>
    )
}