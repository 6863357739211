import LivoIcon from "assets/LivoLogo";
import { ModalContainer } from "components/common/ModalContainer";
import { ADD_CAPACITY_MODAL_BUTTON, ADD_CAPACITY_MODAL_GO_BACK, ADD_CAPACITY_MODAL_TITLE } from "locales/shiftClaimDetails/es";
import React, { useState } from "react";

interface EditCapacityModalProps {
    isOpen: boolean,
    addCapacity: (newCapacity: number) => void
    maxCapacity: number
    goBack: () => void
}
export const EditCapacityModal: React.FC<EditCapacityModalProps> = ({
    isOpen,
    addCapacity,
    maxCapacity,
    goBack
}) => {
    const [numberOfSpots, setNumberOfSpots] = useState(1);

    return (
        <ModalContainer
            isOpen={
                isOpen
            }
        >
            <div
                className="p-medium rounded-[16px] shadow-custom w-[410px] bg-white"
            >
                <p
                    className="heading-md mb-large"
                >
                    {ADD_CAPACITY_MODAL_TITLE}
                </p>
                <div
                    className="flex flex-row items-center space-x-medium justify-center"
                >
                    <button
                        disabled={numberOfSpots <= 1}
                        onClick={() => setNumberOfSpots((nSpots) => nSpots - 1)}
                    >
                        <LivoIcon name='minus' size={24} color={numberOfSpots > 1 ? '#149EF2' : '#DAE4E7'} />

                    </button>
                    <div
                        className="flex w-[90px] py-medium items-center justify-center text-center ring-[1px] ring-solid ring-[#ACBBCB] rounded-[4px]"
                    >
                        <p
                            className="body-regular text-[#055186] h-full w-full self-center"
                        >
                            {numberOfSpots}
                        </p>

                    </div>
                    <button
                        disabled={numberOfSpots >= maxCapacity}
                        onClick={() => setNumberOfSpots((nSpots) => nSpots + 1)}
                    >
                        <LivoIcon name='plus' size={24} color={numberOfSpots < maxCapacity ? '#149EF2' : '#DAE4E7'} />

                    </button>
                </div>
                <div
                    className="flex flex-row items-center mt-large"
                >
                    <button
                        onClick={() => goBack()}
                        className="flex flex-1 text-center text-Action-Primary items-center justify-center  py-large px-small"
                    >
                        <p
                            className="action-regular w-full"
                        >
                            {ADD_CAPACITY_MODAL_GO_BACK}
                        </p>

                    </button>
                    <button
                        className="flex flex-1 py-large px-small rounded-[100px] text-center bg-Action-Primary text-Text-Inverse"
                        onClick={() => addCapacity(numberOfSpots)}
                    >
                        <p
                            className="w-full action-regular"
                        >
                            {ADD_CAPACITY_MODAL_BUTTON}
                        </p>
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}